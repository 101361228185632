<template>
  <svg
    width="58"
    height="59"
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="29"
      cy="29.5"
      r="27.4854"
      stroke="#16ACD9"
      stroke-opacity="0.5"
      stroke-width="2.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.125 20.6388C40.7307 21.204 40.7634 22.1532 40.1981 22.7588L26.1981 37.7588C25.9206 38.0562 25.5341 38.2281 25.1274 38.2351C24.7207 38.2421 24.3285 38.0837 24.0409 37.796L17.0409 30.796C16.4551 30.2102 16.4551 29.2605 17.0409 28.6747C17.6267 28.0889 18.5764 28.0889 19.1622 28.6747L25.0644 34.5768L38.005 20.7119C38.5702 20.1063 39.5194 20.0735 40.125 20.6388Z"
      fill="#16ACD9"
    />
  </svg>
</template>
<script>
export default {
  name: 'RoundedCheckMark',
}
</script>

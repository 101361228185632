<template>
  <b-card no-body>
    <b-row>
      <b-col class="col-12 col-md-8">
        <b-nav class="pt-2 px-2 nav-tabs-wallet">
          <b-nav-item
            id="intro_transactions_tab"
            :to="{ name: 'transactions' }"
          >
            Transactions
          </b-nav-item>
          <b-nav-item
            v-if="isBrand"
            id="intro_savings_tab"
            class="ml-1"
            :to="{ name: 'savings' }"
          >
            Savings
          </b-nav-item>
          <b-nav-item
            v-if="isRetailer"
            id="intro_rewards_tab"
            class="ml-1"
            :to="{ name: 'rewards' }"
          >
            Rewards
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col
        v-if="shouldDisplayNetTermsButton && isRetailer"
        class="col-12 col-md-4 mt-1 mt-md-0 d-flex align-items-end justify-content-end"
      >
        <b-button
          variant="secondary-info mr-1"
          @click="$bvModal.show('credit-application')"
        >
          Apply for Net Terms
        </b-button>
      </b-col>
    </b-row>
    <credit-application-form
      :tab="tab"
      :missing-fields="missingFields"
      @hide-apply-net-terms="hideApplyNetTerms"
    />
    <div>
      <router-view />
    </div>
    <tool-kit :toolkit-page="TOOLKIT_PAGE.WALLET " />
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BCard,
  BNav,
  BNavItem,
  BRow,
  BCol,
  BButton,
  } from 'bootstrap-vue'
import { TOOLKIT_PAGE } from '@/constants'
import { VERIFY_CREDIT_APPLICATION } from '@/store/modules/wallet.module'
import { apiToastWarning } from '@/@core/utils/toast'
import CreditApplicationForm from './CreditApplicationForm.vue'
import UserRoleMixin from '../UserRoleMixin.vue'

export default {
  name: 'Transaction',
  components: {
    BCard,
    BNav,
    BNavItem,
    BRow,
    BCol,
    BButton,
    CreditApplicationForm,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      TOOLKIT_PAGE,
      shouldDisplayNetTermsButton: false,
      tab: 0,
      missingFields: [],
    }
  },
  mounted() {
    if (this.isRetailer) {
      this.verifyCreditApplication()
    }
  },
  methods: {
    verifyCreditApplication() {
      store.dispatch(VERIFY_CREDIT_APPLICATION)
      .then(({ isUserProfileComplete, canCreateApplication, missingFields = [] }) => {
          this.shouldDisplayNetTermsButton = canCreateApplication
          this.missingFields = missingFields
        if (isUserProfileComplete) {
          this.tab = 1
        }
      })
      .catch(err => {
        apiToastWarning(err)
      })
    },
    hideApplyNetTerms() {
      this.shouldDisplayNetTermsButton = false
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/pages/app-wallet.scss';
.nav-tabs-wallet {
  padding-left: 8px;
.router-link-active {
  color: $body-color;
}
.nav-item {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  padding: 0 12px 12px 12px;
  text-transform: uppercase;
  position: relative;
  .nav-link {
    color: $sonic-silver;
    padding: 0;
    &.router-link-active {
      color: $body-color;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 3px;
        transition: transform 0.3s;
        background: $body-color;
      }
    }
  }
}
}
</style>

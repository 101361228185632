<template>
  <b-modal
    id="credit-application"
    :modal-class="{
      'custom-modal-variant custom-model': true,
      'credit-application-model': tabIndex === 3,
    }"
    centered
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    hide-footer
    header-class="py-1"
    @show="shownCreditApplicationForm"
  >
    <template #modal-title>
      <div class="elements-center">
        <bank-icon />
        <span class="ml-1"> Net Terms Application </span>
      </div>
    </template>
    <b-tabs
      v-model="tabIndex"
      class="credit-application-tabs color-neutral-black"
    >
      <b-tab>
        <div class="color-neutral-black">
          <p>Please fill in your profile before proceeding</p>
          <template v-if="missingFields.length">
            <p class="font-weight-bold">
              Information missing:
            </p>
            <ul>
              <li
                v-for="field in missingFields"
                :key="field"
              >
                {{ field }}
              </li>
            </ul>
          </template>
        </div>
        <div class="elements-center my-1">
          <b-button
            variant="outline-secondary-info"
            class="mr-1"
            @click="$bvModal.hide('credit-application')"
          >
            Back
          </b-button>
          <b-button
            variant="secondary-info"
            link
            :to="{ name: 'profile' }"
          >
            Go to profile
          </b-button>
        </div>
      </b-tab>
      <b-tab>
        <div class="d-flex">
          <b-form-group class="w-75 mr-1">
            <template #label>
              Credit Amount <span class="text-danger"> *</span>
            </template>
            <b-form-input
              v-model="creditAmount"
              type="number"
              placeholder="Credit Amount"
            />
          </b-form-group>
          <b-form-group class="w-25">
            <template #label>
              Currency <span class="text-danger"> *</span>
            </template>
            <b-dropdown
              class="w-100 currency-dropdown"
              variant="outline-none"
              no-caret
            >
              <template #button-content>
                {{ currency || 'Select currency' }}
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item
                :active="currency === CURRENCY_CONVERSIONS.USD.LABEL"
                @click="currency = CURRENCY_CONVERSIONS.USD.LABEL"
              >
                {{ currency }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </div>
        <b-form-group class="w-100">
          <template #label>
            Payment Terms <span class="text-danger"> *</span>
          </template>
          <b-dropdown
            class="w-100 currency-dropdown"
            variant="outline-none"
            no-caret
            :toggle-class="!paymentTerms ? 'color-cader-gray' : ''"
          >
            <template #button-content>
              {{
                paymentTerms
                  ? PAYMENT_TERMS_CREDIT_APPLICATION[paymentTerms].TEXT
                  : 'Select payment terms'
              }}
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
              />
            </template>
            <b-dropdown-item
              v-for="paymentTerm of Object.keys(PAYMENT_TERMS_CREDIT_APPLICATION)"
              :key="paymentTerm"
              :active="paymentTerms === paymentTerm"
              @click="paymentTerms = paymentTerm"
            >
              {{ PAYMENT_TERMS_CREDIT_APPLICATION[paymentTerm].TEXT }}
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
        <b-form-group class="w-100">
          <template #label>
            Annual Revenue <span class="text-danger"> *</span>
          </template>
          <b-dropdown
            class="w-100 currency-dropdown"
            variant="outline-none"
            no-caret
            :toggle-class="!annualRevenues ? 'color-cader-gray' : ''"
          >
            <template #button-content>
              {{
                annualRevenues
                  ? ANNUAL_REVENVUE[annualRevenues].TEXT
                  : 'Select Annual Revenue'
              }}
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
              />
            </template>
            <b-dropdown-item
              v-for="annualRevenue of Object.keys(ANNUAL_REVENVUE)"
              :key="annualRevenue"
              :active="annualRevenues === annualRevenue"
              @click="annualRevenues = annualRevenue"
            >
              {{ ANNUAL_REVENVUE[annualRevenue].TEXT }}
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
        <div class="elements-center p-1">
          <b-button
            variant="outline-secondary-info mr-1"
            @click="$bvModal.hide('credit-application')"
          >
            Cancel
          </b-button>
          <b-button
            variant="secondary-info"
            :disabled="!isFormFilled"
            @click="beginApplication"
          >
            Begin Application
          </b-button>
        </div>
      </b-tab>
      <b-tab>
        <div class="w-100 d-flex flex-wrap">
          <b-form-group :class="(secureTypeFile && isNotCreditCard) ? 'w-100': 'w-50'">
            <template #label>
              How would you like to secure credit?
              <span class="text-danger">*</span>
            </template>
            <b-dropdown
              class="w-100 currency-dropdown"
              variant="outline-none"
              no-caret
            >
              <template #button-content>
                <span :class="!creditSecureType && 'color-cader-gray'">
                  {{ creditSecureType || 'Select Secure Type' }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item
                v-for="creditSecureTypeOption of creditSecureTypeOptions"
                :key="creditSecureTypeOption"
                :active="creditSecureType === creditSecureTypeOption"
                @click="creditSecureType = creditSecureTypeOption"
              >
                {{ creditSecureTypeOption }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
          <b-form-group
            v-if="!secureTypeFile && isNotCreditCard"
            class="w-50 d-flex align-items-end justify-content-end"
            label-for="secure-type"
            label-class="mb-0 w-50"
          >
            <template #label>
              <div class="btn btn-outline-info w-100">
                <upload-icon class="upload-icon-svg" />
                Upload
              </div>
            </template>
            <b-form-file
              id="secure-type"
              v-model="secureTypeFile"
              plain
              hidden="true"
            />
          </b-form-group>
          <div
            v-if="secureTypeFile && isNotCreditCard"
            class="uploaded-file w-100"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <b-img
                  :src="pdfIcon"
                  alt="ErrorIcon"
                />
                <div class="file-name-size ml-1">
                  <div class="text-truncate">
                    {{ textTruncate(secureTypeFile.name) }}
                  </div>
                  <div>
                    Size: {{ formatNumber(secureTypeFile.size / 1024, 2) }} KB
                  </div>
                </div>
              </div>
              <div @click="secureTypeFile = null">
                <feather-icon
                  class="text-danger cursor-pointer"
                  icon="XIcon"
                  size="20"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex">
          <b-form-group class="w-50">
            <template #label>
              Proof of Address
              <span class="text-danger">*</span>
            </template>
            <div class="color-actions-text-grey">
              Utility bill, rent contract, etc.
            </div>
          </b-form-group>
          <b-form-group
            v-if="!proofOfAddressFile"
            class="w-50 d-flex align-items-end justify-content-end"
            label-for="proof-of-address"
            label-class="mb-0 w-50"
          >
            <template #label>
              <div class="btn btn-outline-info w-100">
                <upload-icon class="upload-icon-svg" />
                Upload
              </div>
            </template>
            <b-form-file
              id="proof-of-address"
              v-model="proofOfAddressFile"
              plain
              hidden="true"
            />
          </b-form-group>
          <div
            v-else
            class="uploaded-file"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-img
                :src="pdfIcon"
                alt="ErrorIcon"
              />
              <div class="file-name-size">
                <div class="text-truncate">
                  {{ textTruncate(proofOfAddressFile.name) }}
                </div>
                <div>
                  Size: {{ formatNumber(proofOfAddressFile.size / 1024, 2) }} KB
                </div>
              </div>
              <div @click="proofOfAddressFile = null">
                <feather-icon
                  class="text-danger cursor-pointer"
                  icon="XIcon"
                  size="20"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isCreditAmountLesser"
          class="w-100 d-flex"
        >
          <b-form-group class="w-50">
            <template #label>
              Proof of Identity
              <span class="text-danger">*</span>
            </template>
            <div class="color-actions-text-grey">
              Passport
            </div>
          </b-form-group>
          <b-form-group
            v-if="!proofOfIdentityFile"
            class="w-50 d-flex align-items-end justify-content-end"
            label-for="proof-of-identity"
            label-class="mb-0 w-50"
          >
            <template #label>
              <div class="btn btn-outline-info w-100">
                <upload-icon class="upload-icon-svg" />
                Upload
              </div>
            </template>
            <b-form-file
              id="proof-of-identity"
              v-model="proofOfIdentityFile"
              plain
              hidden="true"
            />
          </b-form-group>
          <div
            v-else
            class="uploaded-file"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-img
                :src="pdfIcon"
                alt="ErrorIcon"
              />
              <div class="file-name-size">
                <div class="text-truncate">
                  {{ textTruncate(proofOfIdentityFile.name) }}
                </div>
                <div>
                  Size:
                  {{ formatNumber(proofOfIdentityFile.size / 1024, 2) }} KB
                </div>
              </div>
              <div @click="proofOfIdentityFile = null">
                <feather-icon
                  class="text-danger cursor-pointer"
                  icon="XIcon"
                  size="20"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex">
          <b-form-group class="w-50 d-flex align-items-center">
            Bank Statement
          </b-form-group>
          <b-form-group
            v-if="!bankStatementFile"
            class="w-50 d-flex align-items-end justify-content-end"
            label-for="bank-statement"
            label-class="mb-0 w-50"
          >
            <template #label>
              <div class="btn btn-outline-info w-100">
                <upload-icon class="upload-icon-svg" />
                Upload
              </div>
            </template>
            <b-form-file
              id="bank-statement"
              v-model="bankStatementFile"
              plain
              hidden="true"
            />
          </b-form-group>
          <div
            v-else
            class="uploaded-file"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-img
                :src="pdfIcon"
                alt="ErrorIcon"
              />
              <div class="file-name-size">
                <div class="text-truncate">
                  {{ textTruncate(bankStatementFile.name) }}
                </div>
                <div>
                  Size: {{ formatNumber(bankStatementFile.size / 1024, 2) }} KB
                </div>
              </div>
              <div @click="bankStatementFile = null">
                <feather-icon
                  class="text-danger cursor-pointer"
                  icon="XIcon"
                  size="20"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="elements-center p-1">
          <b-button
            variant="outline-secondary-info mr-1"
            @click="tabIndex--"
          >
            Back
          </b-button>
          <b-button
            variant="secondary-info"
            :disabled="!isDocumentUploaded"
            @click="tabIndex++"
          >
            Next
          </b-button>
        </div>
      </b-tab>
      <b-tab>
        <validation-observer
          #default="{invalid}"
        >
          <div class="contacts-form">
            <p class="title">
              Additional Contacts
            </p>
            <div class="d-flex align-items-start">
              <div class="w-25 title mt-2">
                Finance Contact:
              </div>
              <b-form-group
                class="w-25 mr-1"
                label="Name"
                label-for="finance-name"
              >
                <b-form-input
                  id="finance-name"
                  v-model="financeForm.name"
                  placeholder="Finance Name"
                />
              </b-form-group>
              <b-form-group
                class="w-25 mr-1"
                label="Email"
                label-for="finance-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="name"
                  rules="email"
                >
                  <b-form-input
                    id="finance-email"
                    v-model="financeForm.email"
                    type="email"
                    placeholder="Finance Email"
                  />
                  <small
                    v-if="errors.length"
                    class="text-danger"
                  > Entered email is invalid</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                class="w-25"
                label="Phone"
                label-for="finance-phone"
              >
                <vue-tel-input
                  v-model="financeForm.phone"
                  class="form-control"
                  mode="international"
                  :input-options="financeTelInputOption"
                  style-classes="form-telephone-input"
                />
              </b-form-group>
            </div>
            <div class="d-flex align-items-start">
              <div class="w-25 title mt-2">
                Logistics Contact:
              </div>
              <b-form-group
                class="w-25 mr-1"
                label="Name"
                label-for="Logistics-name"
              >
                <b-form-input
                  id="Logistics-name"
                  v-model="logisticsForm.name"
                  placeholder="Logistics Name"
                />
              </b-form-group>
              <b-form-group
                class="w-25 mr-1"
                label="Email"
                label-for="Logistics-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="name"
                  rules="email"
                >
                  <b-form-input
                    id="Logistics-email"
                    v-model="logisticsForm.email"
                    type="email"
                    placeholder="Logistics Email"
                  />
                  <small
                    v-if="errors.length"
                    class="text-danger"
                  > Entered email is invalid</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                class="w-25"
                label="Phone"
                label-for="Logistics-phone"
              >
                <vue-tel-input
                  v-model="logisticsForm.phone"
                  class="form-control"
                  mode="international"
                  :input-options="logisticsTelInputOption"
                  style-classes="form-telephone-input"
                />
              </b-form-group>
            </div>
            <p class="title mt-1">
              Please provide us with references
            </p>
            <div
              v-for="(form, index) in othersForm"
              :key="index"
              class="d-flex align-items-start"
            >
              <b-form-group
                class="w-25 mr-1"
                label="Company Name"
                label-for="contact-name"
              >
                <b-form-input
                  id="contact-name"
                  v-model="form.companyName"
                  placeholder="Company Name"
                />
              </b-form-group>
              <b-form-group
                class="w-25 mr-1"
                label="Contact Name"
                label-for="contact-name"
              >
                <b-form-input
                  id="contact-name"
                  v-model="form.name"
                  placeholder="Name"
                />
              </b-form-group>
              <b-form-group
                class="w-25 mr-1"
                label="Email"
                label-for="contact-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="name"
                  rules="email"
                >
                  <b-form-input
                    id="contact-email"
                    v-model="form.email"
                    type="email"
                    placeholder="Contact Email"
                  />
                  <small
                    v-if="errors.length"
                    class="text-danger"
                  > Entered email is invalid</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                class="w-25"
                label="Phone"
                label-for="contact-phone"
              >
                <vue-tel-input
                  v-model="form.phone"
                  class="form-control"
                  mode="international"
                  name="phone"
                  :input-options="othersTelInputOption"
                  style-classes="form-telephone-input drop-up"
                />
              </b-form-group>
            </div>
            <div class="add-form">
              <div class="line" />
              <div
                class="svg-wrapper"
                @click="addOthersForm"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="20"
                />
              </div>
              <div class="line" />
            </div>
          </div>
          <div class="elements-center p-1">
            <b-button
              variant="outline-secondary-info mr-1"
              :disabled="loading"
              @click="tabIndex--"
            >
              Back
            </b-button>
            <b-button
              variant="secondary-info"
              :disabled="loading || invalid"
              @click="submitApplication"
            >
              {{ loading ? 'Sending Application' : 'Send Application' }}
            </b-button>
          </div>
        </validation-observer>
      </b-tab>
      <b-tab>
        <div class="elements-center flex-column">
          <rounded-check-mark />
          <p class="color-dark-blue mt-1 font-weight-bolder">
            Credit Application submitted successfully.
          </p>
          <p class="color-actions-text-grey text-center">
            Your Credit Application was issued successfully, our Admins will
            <br>
            notify you by emails once your application is reviewed
          </p>
        </div>
        <div class="elements-center p-1">
          <b-button
            variant="secondary-info"
            @click="$bvModal.hide('credit-application')"
          >
            Go back to the Wallet
          </b-button>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import {
  BModal,
  BTab,
  BTabs,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import BankIcon from '@/@core/assets/svg-components/BankIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import UploadIcon from '@/@core/assets/svg-components/UploadIcon.vue'
import { formatNumber, textTruncate } from '@/@core/utils/utils'
import RoundedCheckMark from '@/@core/assets/svg-components/RoundedCheckMark.vue'
import store from '@/store'
import {
  CREATE_NEW_CREDIT_APPLICATION,
} from '@/store/modules/wallet.module'
import { CREDIT_APPLICATION_UPPER_LIMIT, FILE_TYPES } from '@/constants'
import { apiToastWarning } from '@/@core/utils/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'

const {
 CURRENCY_CONVERSIONS,
  PAYMENT_TERMS_CREDIT_APPLICATION,
  ANNUAL_REVENVUE,
  CREDIT_TYPE,
} = c

export default {
  components: {
    BModal,
    BTab,
    BTabs,
    BButton,
    BankIcon,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    FeatherIcon,
    BFormFile,
    UploadIcon,
    BImg,
    RoundedCheckMark,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    tab: {
      type: Number,
      default: 4,
    },
    missingFields: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      tabIndex: 3,
      creditAmount: null,
      CURRENCY_CONVERSIONS,
      currency: CURRENCY_CONVERSIONS.USD.LABEL,
      PAYMENT_TERMS_CREDIT_APPLICATION,
      paymentTerms: null,
      ANNUAL_REVENVUE,
      annualRevenues: null,
      creditSecureType: null,
      secureTypeFile: null,
      proofOfAddressFile: null,
      bankStatementFile: null,
      proofOfIdentityFile: null,
      secureTypeFileLink: null,
      proofOfAddressFileLink: null,
      bankStatementFileLink: null,
      proofOfIdentityFileLink: null,
      textTruncate,
      formatNumber,
      pdfIcon: require('@/assets/images/icons/pdficon.svg'),
      financeForm: this.buildDefaultFinanceForm(),
      logisticsForm: this.buildDefaultLogisticsForm(),
      othersForm: [this.buildDefaultOthersForm()],
      loading: false,
      CREDIT_APPLICATION_UPPER_LIMIT,
    }
  },
  emits: ['hide-apply-net-terms'],
  computed: {
    isFormFilled() {
      return this.creditAmount > 0 && this.paymentTerms && this.annualRevenues
    },
    // Determines is Secure Type is Credit Card or not
    isNotCreditCard() {
      return this.creditSecureType !== 'Credit Card'
    },
    isDocumentUploaded() {
      const documentsUploaded = this.creditSecureType && (!this.isNotCreditCard || this.secureTypeFile) && this.proofOfAddressFile
      if (this.isCreditAmountLesser) {
        return this.proofOfIdentityFile && documentsUploaded
      }
      return documentsUploaded
    },
    isCreditAmountLesser() {
      return this.creditAmount <= CREDIT_APPLICATION_UPPER_LIMIT
    },
    creditSecureTypeOptions() {
      if (this.isCreditAmountLesser) {
        return CREDIT_TYPE.LESS_THAN_30K
      }
      return CREDIT_TYPE.MORE_THAN_30K
    },
    financeTelInputOption() {
      return {
        id: 'finance-tel-input',
        placeholder: 'Finance Phone',
      }
    },
    logisticsTelInputOption() {
      return {
        id: 'logistics-tel-input',
        placeholder: 'Logistics Phone',
      }
    },
    othersTelInputOption() {
      return {
        id: 'others-tel-input',
        placeholder: 'Contact Phone',
      }
    },
  },
  mounted() {},
  methods: {
    buildDefaultFinanceForm() {
      return {
        role: 'finance',
        name: null,
        email: null,
        phone: null,
      }
    },
    buildDefaultLogisticsForm() {
      return {
        role: 'logistics',
        name: null,
        email: null,
        phone: null,
      }
    },
    buildDefaultOthersForm() {
      return {
        role: 'references',
        name: null,
        companyName: null,
        email: null,
        phone: null,
      }
    },
    shownCreditApplicationForm() {
      this.tabIndex = this.tab
      this.resetForm()
    },
    resetForm() {
      this.creditAmount = null
      this.currency = CURRENCY_CONVERSIONS.USD.LABEL
      this.paymentTerms = null
      this.annualRevenues = null
      this.creditSecureType = null
      this.secureTypeFile = null
      this.proofOfAddressFile = null
      this.bankStatementFile = null
      this.proofOfIdentityFile = null
      this.financeForm = this.buildDefaultFinanceForm()
      this.logisticsForm = this.buildDefaultLogisticsForm()
      this.othersForm = [this.buildDefaultOthersForm()]
    },
    async uploadDocuments(fileType, file) {
      return store.dispatch(UPLOAD_DOCUMENTS, { fileType, file })
    },
    beginApplication() {
      if (!this.creditSecureTypeOptions.includes(this.creditSecureType)) {
        this.creditSecureType = null
      }
      this.tabIndex++
    },
    checkObjectHasValues(obj) {
      const asssigningObj = {}
      Object.entries(obj).forEach(([key, value]) => {
        if (value) {
          asssigningObj[`${key}`] = value
        }
      })
      return asssigningObj
    },
    addOthersForm() {
      this.othersForm.push(this.buildDefaultOthersForm())
    },
    async submitApplication() {
      this.loading = true
      if (this.secureTypeFile && this.isNotCreditCard) {
        this.secureTypeFileLink = await this.uploadDocuments(
          FILE_TYPES.SECURITY_DOCUMENT,
          this.secureTypeFile,
        )
      }
      if (this.proofOfAddressFile) {
        this.proofOfAddressFileLink = await this.uploadDocuments(
          FILE_TYPES.PROOF_OF_ADDRESS,
          this.proofOfAddressFile,
        )
      }
      if (this.bankStatementFile) {
        this.bankStatementFileLink = await this.uploadDocuments(
          FILE_TYPES.BANK_STATEMENT,
          this.bankStatementFile,
        )
      }
      if (this.isCreditAmountLesser && this.proofOfIdentityFile) {
        this.proofOfIdentityFileLink = await this.uploadDocuments(
          FILE_TYPES.PROOF_OF_IDENTITY,
          this.proofOfIdentityFile,
        )
      }
      const contacts = []
      const financeFormValue = this.checkObjectHasValues(this.financeForm)
      if (Object.keys(financeFormValue).length > 1) {
        contacts.push(financeFormValue)
      }
      const logisticsFormValue = this.checkObjectHasValues(this.logisticsForm)
      if (Object.keys(logisticsFormValue).length > 1) {
        contacts.push(logisticsFormValue)
      }
      this.othersForm.forEach(form => {
        const formValue = this.checkObjectHasValues(form)
        if (Object.keys(formValue).length > 1) {
          contacts.push(formValue)
        }
      })
      const payload = {
        creditAmount: Number(this.creditAmount),
        currency: this.currency,
        paymentTerms: PAYMENT_TERMS_CREDIT_APPLICATION[this.paymentTerms].TEXT,
        annualRevenue: ANNUAL_REVENVUE[this.annualRevenues].TEXT,
        securityDocumentType: this.creditSecureType,
        proofOfAddress: this.proofOfAddressFileLink,
        contacts,
      }
      if (this.bankStatementFileLink) {
        payload.bankStatement = this.bankStatementFileLink
      }
      if (this.secureTypeFile && this.isNotCreditCard) {
        payload.securityDocument = this.secureTypeFileLink
      }
      if (this.proofOfIdentityFileLink) {
        payload.proofOfIdentity = this.proofOfIdentityFileLink
      }
      store
        .dispatch(CREATE_NEW_CREDIT_APPLICATION, payload)
        .then(() => {
          this.tabIndex = 4
          this.loading = false
          this.$emit('hide-apply-net-terms')
        })
        .catch(err => {
          apiToastWarning(err)
          this.loading = false
        })
    },
  },
}
</script>

<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6934 5.98936C11.0016 5.98936 11.2972 5.86693 11.5151 5.649C11.7331 5.43107 11.8555 5.13549 11.8555 4.82729C11.8555 4.51909 11.7331 4.22351 11.5151 4.00558C11.2972 3.78765 11.0016 3.66522 10.6934 3.66522C10.3852 3.66522 10.0896 3.78765 9.8717 4.00558C9.65377 4.22351 9.53134 4.51909 9.53134 4.82729C9.53134 5.13549 9.65377 5.43107 9.8717 5.649C10.0896 5.86693 10.3852 5.98936 10.6934 5.98936ZM19.0587 16.3985V8.95345H19.1869C20.432 8.95345 20.9436 7.3529 19.9259 6.63209L11.7697 0.84334C11.4553 0.619953 11.0791 0.499939 10.6934 0.499939C10.3077 0.499939 9.93153 0.619953 9.61708 0.84334L1.45817 6.63209C0.44319 7.3529 0.952076 8.95206 2.1971 8.95206H2.32816V16.3971C1.49024 16.9353 0.933952 17.875 0.933952 18.9444V20.8028C0.933952 20.9877 1.0074 21.165 1.13813 21.2958C1.26886 21.4265 1.44617 21.4999 1.63106 21.4999H19.7558C19.9407 21.4999 20.118 21.4265 20.2487 21.2958C20.3794 21.165 20.4529 20.9877 20.4529 20.8028V18.9444C20.4529 17.875 19.898 16.9353 19.0587 16.3985ZM10.4229 1.98101C10.5014 1.92543 10.5952 1.89558 10.6913 1.89558C10.7875 1.89558 10.8813 1.92543 10.9597 1.98101L18.8217 7.55785H2.56239L10.4229 1.98101ZM17.6617 8.95206V15.9315C17.5847 15.9258 17.5075 15.923 17.4302 15.9231H14.8733V8.95345L17.6617 8.95206ZM3.72237 15.9315V8.95345H6.51079V15.9245H3.95381C3.87573 15.9245 3.79766 15.9273 3.72098 15.9329L3.72237 15.9315ZM3.9552 17.3173H17.433C18.3309 17.3173 19.0587 18.0451 19.0587 18.9444V20.1057H2.32816V18.9444C2.32816 18.0451 3.05733 17.3173 3.9552 17.3173ZM9.99631 15.9231H7.905V8.95345H9.99631V15.9231ZM11.3905 15.9231V8.95345H13.4818V15.9245L11.3905 15.9231Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'BankIcon',
}
</script>
